'use strict';

var React = require("react");
var Layout = require("../components/Layout.js");
var Timeline = require("../components/Timeline.js");
var ReactHelmet = require("react-helmet");

function TagPageTemplate(Props) {
  var pageContext = Props.pageContext;
  var data = Props.data;
  var posts = data.allMarkdownRemark.edges;
  var title = "#" + pageContext.tag;
  return React.createElement(Layout.make, {
              children: React.createElement(Timeline.List.make, {
                    children: null
                  }, React.createElement(ReactHelmet.Helmet, {
                        title: title
                      }), React.createElement(Timeline.RowMajor.make, {
                        title: title,
                        iconName: "tag"
                      }), posts.map((function (post) {
                          return React.createElement(Timeline.Row.make, {
                                      post: post,
                                      key: post.node.id
                                    });
                        })))
            });
}

var make = TagPageTemplate;

exports.make = make;
/* react Not a pure module */
